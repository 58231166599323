<template>
  <div></div>
</template>

<script>

import { info } from "@/api/user"
import { setToken, setUserInfo } from "@/utils/auth"
import { getRedirect } from "@/utils/login"


export default {
  name: 'login_callback',
  data() {

    const { uid = 0, access_token = null } = this.$route.query

    return {
      uid: uid,
      access_token: access_token,
    }
  },
  mounted() {
    if (!this.uid || !this.access_token) {
      this.$message({
        message: '登录回调参数异常，正在返回主页',
        type: 'error',
        onClose: () => {
          this.$router.push({ path: '/' })
        }
      });

      return
    }

    // 获取用户信息
    info({ access_token: this.access_token }).then(res => {
      console.info(res)
      setToken(this.access_token)
      setUserInfo(res.data)

      this.$message({
        message: '登录成功！',
        type: 'success'
      });

      this.$store.dispatch('user/userInitAction')

      const target = getRedirect();
      console.info(target)
      this.$router.push({ path: target })
    })

  }
}
</script>

<style lang="css" scoped>
</style>