import request from '@/utils/request'

/**
 * 获取文化培训课程列表
 * @param {*} data 
 */
export function info(data) {
    return request({
        url: '/v2/user/users/getInfo',
        method: 'post',
        params: data
    })
}

/**
 * 修改用户信息
 * access_token	是	string	token值
    headimgurl	是	string	头像地址
    mobile_number	是	string	手机号
    email	是	string	邮箱
    province	否	string	省份
    city	否	string	城市
 * @param {*} data 
 */
export function update(data) {
    return request({
        url: '/v2/user/users/update',
        method: 'post',
        params: data
    })
}